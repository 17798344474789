import React, { useState } from "react";
import "./index.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import add from "../../images/add.png";
import glob from "../../images/glob.png";
import arrow_right from "../../images/arrow_right.png";
import payments from "../../images/payments.png";
import email from "../../images/email.png";
import { Button, Input } from "antd";

export default function Index() {
  const [activity, setActivity] = useState([
    {
      id: 1,
      link: "/",
      title: "Desert Safari",
    },
    {
      id: 2,
      link: "/",
      title: "Dhow Cruise",
    },
    {
      id: 3,
      link: "/",
      title: "City Tours",
    },
    {
      id: 4,
      link: "/",
      title: "Tour Package",
    },
  ]);
  const [support, setSupport] = useState([
    {
      id: 1,
      link: "/contact",
      title: "Contact Us",
    },
    {
      id: 2,
      link: "/terms",
      title: "Terms & Condition",
    },
    {
      id: 3,
      link: "/privacy",
      title: "Privacy",
    },
  ]);

  const [about, setAbout] = useState([
    {
      id: 1,
      link: "/about",
      title: "About Us",
    },
    {
      id: 2,
      link: "/career",
      title: "Career Page",
    },
  ]);

  return (
    <Container className="footer-main-container">
      <Row className="footer-primary-bottom">
        <Col md={6}>
          <span
            className="get-in-touch"
            style={{ color: "#000000", marginLeft: "0px" }}
          >
            Get in touch
          </span>
        </Col>
        <Col md={6} className="text-end">
          <span
            className="get-in-touch "
            style={{ color: "#000000", marginLeft: "0px" }}
          >
            Linkedin / Facebook / Instagram
          </span>
        </Col>
      </Row>
      <Row style={{ marginBottom: "100px" }}>
        <Col md={6}>
          <span
            className="get-in-touch"
            style={{ color: "#000000", marginLeft: "0px", fontSize: "12px" }}
          >
            2022-2024 © Aladdin Adventure Travel & Tours LLC
          </span>
        </Col>
        <Col md={6} className="text-end">
          <Image src={payments} className="payment-image" />
        </Col>
      </Row>
    </Container>
  );
}
