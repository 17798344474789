import React, { useState, useEffect } from "react";
import { Container, Nav, Navbar, Form, Button, Image } from "react-bootstrap";
import logo from "../../images/logo.jpeg";
import { TiShoppingCart } from "react-icons/ti";
import { RiAccountCircleLine } from "react-icons/ri";

import "./index.css";
import { Badge } from "antd";
export default function Index() {
  const [cartNum, setCartNum] = useState(0);
  const [session, setSession] = useState(false);
  const [name, setName] = useState("");
  const getCart = () => {
    let product = localStorage.getItem("falcon_cart");
    let user_session = localStorage.getItem("falcon_user");
    if (product === null) {
      console.log("yes", product);
    } else {
      if (Array.isArray(product) && product !== null) {
      } else {
        setCartNum(JSON.parse(product).length);
        console.log(JSON.parse(product));
      }
    }
    if (user_session === null) {
    } else {
      setSession(true);
      let fullname = localStorage.getItem("falcon_name");
      setName(fullname);
    }
  };

  useEffect(() => {
    getCart();
  });

  return (
    <Navbar expand="lg" className="bg-body header">
      <Container className="header-container">
        <Navbar.Brand href="/">
          <Image src={logo} fluid className="logo" />
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}
