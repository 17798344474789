import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import { Container, Row, Col, Image } from "react-bootstrap";
import desert_safari from "./images/desert_safari.webp";
import { Rating } from "react-simple-star-rating";
import checkmark from "./images/checkmark.png";
import { Button } from "antd";
import { WhatsAppOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import "./index.css";
export default function Index() {
  const [desert_safari, setDesert_safari] = useState([
    {
      pid: 6,
      type: "ECONOMY",
      name: "Canal Cruise Dubai",
      link: "https://falconoasispremium.com/product/canal-cruise-dubai/",
      image:
        "https://dhowcruiseinmarina.com/wp-content/uploads/2024/04/WhatsApp-Image-2023-02-02-at-3.44.19-PM-1.jpeg",
      price: "AED 49",
      off: "10%",
      orignal: "AED 59",
      rating: 4.5,
      review: "134+ review",
      itineary: [
        {
          id: 1,
          title: "2 Hours Cruising To Dubai Water Canal",
        },
        {
          id: 2,
          title: "Welcome Drinks On Arrival",
        },
        {
          id: 3,
          title: "International Buffet Dinner (Veg And Non-Veg)",
        },
        {
          id: 4,
          title: "Unlimited Soft Drinks & Mineral Water",
        },
        {
          id: 5,
          title: "Fully Air-Conditioned Lower Deck & Open-Air Upper Deck",
        },
        {
          id: 6,
          title: "Live Entertainment",
        },
        {
          id: 7,
          title: "Soft Music",
        },
        {
          id: 8,
          title: "Traditional Tanoura Dance",
        },
        {
          id: 9,
          title: "Burj Khalifa View",
        },
        {
          id: 10,
          title: "Dubai Creek Project",
        },
        {
          id: 11,
          title: "Water Show",
        },
        {
          id: 12,
          title: "Flamingo Wildlife",
        },
        {
          id: 13,
          title: "D1 Tower",
        },
        {
          id: 14,
          title: "Creek Harbor Including The New Tallest Tower",
        },
        {
          id: 15,
          title: "Jaddaf Dubai, Dhow Building Yard (Heritage Site)",
        },
        {
          id: 16,
          title:
            "Ras Al Khor Bird Sanctuary (Birds Are Visible During Low Tides)",
        },
        {
          id: 17,
          title: "Dubai Skyline (Sheikh Zayed Road)",
        },
        {
          id: 18,
          title: "Dubai Design District",
        },
        {
          id: 19,
          title: "Downtown Area",
        },
        {
          id: 20,
          title: "Jameel Arts Center Jaddaf",
        },
      ],
    },
    {
      pid: 7,
      name: "Glass Cruise In Dubai Marina With Dinner & Entertainment Shows",
      type: "TRENDING",
      link: "https://falconoasispremium.com/product/glass-cruise-in-dubai-marina-with-dinner-entertainment-shows/",
      image:
        "https://dhowcruiseinmarina.com/wp-content/uploads/2024/06/WhatsApp-Image-2024-06-11-at-12.20.35-2048x1536.jpeg",
      price: "AED 75",
      off: "7%",
      orignal: "AED 82",
      rating: 4.3,
      review: "99+ review",
      itineary: [
        {
          id: 1,
          title: "2 Hours Cruising in Dubai Marina Harbor",
        },
        {
          id: 2,
          title: "Drinks, Water",
        },
        {
          id: 3,
          title: "Buffet Dinner with veg & non veg dishes",
        },
        {
          id: 4,
          title: "Soft Music",
        },
        {
          id: 5,
          title: "Entertainment Shows",
        },
        {
          id: 6,
          title: "Tanoura dance show",
        },
        {
          id: 7,
          title: "𝐑𝐨𝐮𝐭𝐞 & 𝐒𝐢𝐠𝐡𝐭𝐬𝐞𝐞𝐢𝐧𝐠:",
        },
        {
          id: 8,
          title: "Marina skyscrapers",
        },
        {
          id: 9,
          title: "Marina Canal Including",
        },
        {
          id: 10,
          title: "Marina Mall Pier 7",
        },
        {
          id: 11,
          title: "Twisted Tower inside the Canal and outside Dubai Eye",
        },
        {
          id: 12,
          title: "Jumeirah Beach and more",
        },
        {
          id: 13,
          title: "𝐁𝐨𝐚𝐫𝐝𝐢𝐧𝐠 𝐓𝐢𝐦𝐞: 𝟖:𝟎𝟎 𝐏𝐌 (𝐌𝐚𝐫𝐢𝐧𝐚 𝐇𝐚𝐫𝐛𝐨𝐮𝐫, 𝐀𝐀 𝐆𝐚𝐭𝐞)",
        },
        {
          id: 14,
          title: "𝐂𝐫𝐮𝐢𝐬𝐢𝐧𝐠 𝐓𝐢𝐦𝐞: 𝟖:𝟑𝟎 𝐏𝐌 𝐓𝐢𝐥𝐥 𝟏𝟎:𝟑𝟎 𝐏𝐌",
        },
        {
          id: 15,
          title: "𝐍𝐨𝐭𝐞: 𝐏𝐢𝐜𝐤 𝐀𝐧𝐝 𝐃𝐫𝐨𝐩 𝐎𝐟𝐟 𝐀𝐯𝐚𝐢𝐥𝐚𝐛𝐥𝐞 𝐖𝐢𝐭𝐡 𝐄𝐱𝐭𝐫𝐚 𝐏𝐫𝐢𝐜𝐞",
        },
      ],
    },
    {
      pid: 8,
      name: "Mega Dhow Cruise With Dinner In Marina, Dubai",
      type: "BEST SELLER",
      rating: 4.8,
      link: "https://falconoasispremium.com/product/dhow-cruise-with-dinner-in-marina-dubai/",
      review: "124+ review",
      image:
        "https://dhowcruiseinmarina.com/wp-content/uploads/2024/04/WhatsApp-Image-2023-10-30-at-18.14.02-1.webp",
      price: "AED 99",
      off: "4%",
      orignal: "AED 120",
      itineary: [
        {
          id: 1,
          title: "2 Hours Cruising in Dubai Marina Harbor",
        },
        {
          id: 2,
          title: "Welcome Drinks, Water, Tea, Coffee",
        },
        {
          id: 3,
          title: "International buffet with veg & non veg dishes",
        },
        {
          id: 4,
          title: "Unlimited Soft Beverages",
        },
        {
          id: 5,
          title: "Soft Music",
        },
        {
          id: 6,
          title: "Entertainment Shows",
        },
        {
          id: 7,
          title: "Tanoura dance show",
        },
        {
          id: 8,
          title: "Teddy Show",
        },
        {
          id: 9,
          title: "𝐑𝐨𝐮𝐭𝐞 & 𝐒𝐢𝐠𝐡𝐭𝐬𝐞𝐞𝐢𝐧𝐠:",
        },
        {
          id: 10,
          title: "Marina skyscrapers",
        },
        {
          id: 11,
          title: "Marina Canal Including",
        },
        {
          id: 12,
          title: "Marina Mall Pier 7",
        },
        {
          id: 13,
          title: "Twisted Tower inside the Canal and outside Dubai Eye",
        },
        {
          id: 14,
          title: "Jumeirah Beach and more",
        },
        {
          id: 15,
          title: "𝐁𝐨𝐚𝐫𝐝𝐢𝐧𝐠 𝐓𝐢𝐦𝐞: 𝟖:𝟎𝟎 𝐏𝐌 (𝐌𝐚𝐫𝐢𝐧𝐚 𝐇𝐚𝐫𝐛𝐨𝐮𝐫, 𝐀𝐀 𝐆𝐚𝐭𝐞)",
        },
        {
          id: 16,
          title: "𝐂𝐫𝐮𝐢𝐬𝐢𝐧𝐠 𝐓𝐢𝐦𝐞: 𝟖:𝟑𝟎 𝐏𝐌 𝐓𝐢𝐥𝐥 𝟏𝟎:𝟑𝟎 𝐏𝐌",
        },
        {
          id: 17,
          title: "𝐍𝐨𝐭𝐞: 𝐏𝐢𝐜𝐤 𝐀𝐧𝐝 𝐃𝐫𝐨𝐩 𝐎𝐟𝐟 𝐀𝐯𝐚𝐢𝐥𝐚𝐛𝐥𝐞 𝐖𝐢𝐭𝐡 𝐄𝐱𝐭𝐫𝐚 𝐏𝐫𝐢𝐜𝐞",
        },
      ],
    },
    {
      pid: 16,
      name: "Premium Cruise (Luxury Yacht) With Dinner, Dubai",
      type: "BEST SELLER",
      link: "https://falconoasispremium.com/product/premium-dhow-cruise-with-dinner-dubai/",
      rating: 4.8,
      review: "124+ review",
      image:
        "https://dhowcruiseinmarina.com/wp-content/uploads/2024/04/WhatsApp-Image-2024-03-18-at-5.57.53-PM-Copy-Copy-2.jpeg",
      price: "AED 119",
      off: "4%",
      orignal: "AED 138",
      itineary: [
        {
          id: 1,
          title: "5 Star Lavish International BBQ Buffet Dinner",
        },
        {
          id: 2,
          title: "2 Hours Cruising In Dubai Marina",
        },
        {
          id: 3,
          title: "Welcome Drinks, Water, Tea, Coffee",
        },
        {
          id: 4,
          title: "Unlimited Soft Beverages",
        },
        {
          id: 5,
          title: "Soft Music",
        },
        {
          id: 6,
          title: "𝐋𝐢𝐯𝐞 𝐂𝐨𝐨𝐤𝐢𝐧𝐠 𝐒𝐭𝐚𝐭𝐢𝐨𝐧",
        },
        {
          id: 7,
          title: "Live Entertainment Show On Board",
        },
        {
          id: 8,
          title: "Tanoura Dance Shows",
        },
        {
          id: 9,
          title: "Access To AC Lower Deck And Open Air Upper Deck",
        },
        {
          id: 10,
          title: "Separate Amenity Facilities For Men & Women",
        },
        {
          id: 11,
          title: "𝐁𝐞𝐚𝐮𝐭𝐢𝐟𝐮𝐥 𝐒𝐢𝐠𝐡𝐭𝐬𝐞𝐞𝐢𝐧𝐠 𝐎𝐟 𝐃𝐮𝐛𝐚𝐢 𝐌𝐚𝐫𝐢𝐧𝐚:",
        },
        {
          id: 12,
          title: "Dubai Marina",
        },
        {
          id: 13,
          title: "Marina Canal",
        },
        {
          id: 14,
          title: "Marina Mall Pier 7",
        },
        {
          id: 15,
          title: "Twisted Tower Inside The Canal And Outside Dubai Eye",
        },
        {
          id: 16,
          title: "Jumeirah Beach And More (Subject To Weather Conditions)",
        },
        {
          id: 17,
          title: "𝐁𝐨𝐚𝐫𝐝𝐢𝐧𝐠 𝐓𝐢𝐦𝐞: 𝟖:𝟎𝟎 𝐏𝐌 (𝐌𝐚𝐫𝐢𝐧𝐚 𝐇𝐚𝐫𝐛𝐨𝐮𝐫, 𝐀𝐀 𝐆𝐚𝐭𝐞)",
        },
        {
          id: 18,
          title: "𝐂𝐫𝐮𝐢𝐬𝐢𝐧𝐠 𝐓𝐢𝐦𝐞: 𝟖:𝟑𝟎 𝐏𝐌 𝐓𝐢𝐥𝐥 𝟏𝟎:𝟑𝟎 𝐏𝐌",
        },
        {
          id: 19,
          title: "𝐍𝐨𝐭𝐞: 𝐏𝐢𝐜𝐤 𝐀𝐧𝐝 𝐃𝐫𝐨𝐩 𝐎𝐟𝐟 𝐀𝐯𝐚𝐢𝐥𝐚𝐛𝐥𝐞 𝐖𝐢𝐭𝐡 𝐄𝐱𝐭𝐫𝐚 𝐏𝐫𝐢𝐜𝐞",
        },
      ],
    },
    {
      pid: 11,
      name: "Musandam Dhow Cruise Day Trip From Dubai With Lunch",
      type: "BEST SELLER",
      rating: 4.8,
      review: "124+ review",
      link: "https://falconoasispremium.com/product/oman-musandam-dhow-cruise-day-trip-with-lunch-from-dubai/",
      image:
        "https://dhowcruiseinmarina.com/wp-content/uploads/2024/07/WhatsApp-Image-2024-04-22-at-4.44.02-PM-2.jpeg",
      price: "AED 125",
      off: "10%",
      orignal: "AED 145",
      itineary: [
        {
          id: 1,
          title: "5-6 hours dhow cruise",
        },
        {
          id: 2,
          title: "Swimming & Snorkeling",
        },
        {
          id: 3,
          title: "Banana boat ride",
        },
        {
          id: 4,
          title: "Speedboat ride",
        },
        {
          id: 5,
          title: "Sightseeing",
        },
        {
          id: 6,
          title: "Kayaking",
        },
        {
          id: 7,
          title: "White cave (Limestone Cave) visit",
        },
        {
          id: 8,
          title: "Drop-off to beach via speedboat",
        },
        {
          id: 9,
          title: "Fishing with hand-line tools",
        },
        {
          id: 10,
          title: "Entry approvals for the checkpoint.",
        },
        {
          id: 11,
          title: "Lunch on the cruise with unlimited refreshments.",
        },
        {
          id: 12,
          title: "Sharing dhow",
        },
        {
          id: 13,
          title:
            "Two ways transportation from Dubai / Sharjah (Additional Cost)",
        },
      ],
    },
  ]);

  const redirect = (link) => {
    window.location.href = link;
  };
  const whtasapp = (id, name, link) => {
    let message =
      "Hello Aladdin Tours, I want to book " + name + " " + link + id;
    window.location.href =
      "https://api.whatsapp.com/send?phone=971502646557&text=" + message;
  };

  return (
    <Container fluid style={{ padding: 0, margin: 0, position: "relative" }}>
      <Header />

      <Container fluid className="banner_container"></Container>
      <Container className="banner_overlay" fluid>
        <h3 className="overlay_title">Luxury Yatch</h3>
      </Container>
      <Container className="desert_safari_main_container">
        <Row>
          {desert_safari.map((item) => {
            return (
              <Col
                md={4}
                className={
                  item.pid === 6 || item.pid === 8
                    ? "desert_safari_main_col_top_10"
                    : "desert_safari_main_col"
                }
              >
                <div className="desert_safari_inner_item">
                  <div
                    className="item_image_container"
                    style={{ position: "relative" }}
                  >
                    <Image src={item.image} fluid className="item_image" />
                    <div
                      className="off_percentage"
                      style={{
                        position: "absolute",
                        top: "20px",
                        left: "20px",
                        background:
                          "linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%)",
                        borderRadius: "10px",
                        height: "25px",
                      }}
                    >
                      <span
                        className="off_text"
                        style={{ color: "#FFF", fontSize: "12px" }}
                      >
                        {item.type}
                      </span>
                    </div>
                    <div
                      className="off_percentage"
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        background: "#28B0A6",
                      }}
                    >
                      <span className="off_text" style={{ color: "#FFF" }}>
                        {item.off} Off
                      </span>
                    </div>
                  </div>
                  <div className="item_package_name">
                    <h3 className="item_package_title text-left">
                      {item.name}
                    </h3>
                  </div>
                  <div className="item_price_container">
                    <span className="offer_price">{item.price}/-</span>
                    <span className="orignal_price">{item.orignal}/-</span>
                  </div>
                  <div className="item_review_container">
                    <Rating
                      initialValue={item.rating}
                      allowFraction={true}
                      size={15}
                    />
                    <span className="review_text">{item.review}</span>
                  </div>
                  <div className="item_itineary_container">
                    {item.itineary.map((item) => {
                      return (
                        <div className="inner_itineary">
                          <Image src={checkmark} />
                          <span className="review_text">{item.title}/-</span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="item_btn_container">
                    <Button
                      onClick={() => redirect(item.link)}
                      className="item_btn"
                      icon={<ShoppingCartOutlined />}
                      style={{
                        background:
                          "linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%)",
                      }}
                    >
                      Book Now
                    </Button>
                    <Button
                      onClick={() => whtasapp(item.pid, item.name, item.link)}
                      className="item_btn"
                      style={{ background: "#25d366" }}
                      icon={<WhatsAppOutlined />}
                    >
                      Whatsapp
                    </Button>
                  </div>
                </div>

                <br></br>
                <br></br>
              </Col>
            );
          })}
        </Row>
      </Container>
      <Footer />
    </Container>
  );
}
